<template>
  <div class="order_detail">
    <div class="detail_map">
      <span>扫码取号</span>
      <div id="qrcodea"></div>
    </div>
    <!-- <div class="detail_top" ref="detail">
      <div class="top_header">
        <div id="qrcodea" v-show="detailObj.ticketStatus===0"></div>
        <div class="lose_efficacy_bg" v-show="detailObj.ticketStatus!==0"></div>
      </div>
      <div class="top_body">
        <div class="matter">
          <span>{{detailObj.matterName}}</span>
          <span>{{statusDict[detailObj.ticketStatus]}}</span>
        </div>
        <div class="time">
          <div>
            <span>预约日期</span>
            <span>{{detailObj.appointmentDate}}</span>
          </div>
          <div>
            <span>预约时间段</span>
            <span>{{detailObj.startTime}}-{{detailObj.endTime}}</span>
          </div>
        </div>
      </div>
      <div class="top_rows">
        <div class="row">
          <span>预约大厅</span>
          <span>小榄镇政务服务中心</span>
        </div>
        <div class="row">
          <span>预约部门</span>
          <span>民生事项综合窗口</span>
        </div>
        <div class="row">
          <span>办理区域</span>
          <span>A区</span>
        </div>
        <div class="row">
          <span>手机号</span>
          <span>{{userInfo.phone}}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Image,Toast,Dialog,Stepper } from "vant";
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
import { getDictionary,getDetail } from "@/api/order";
export default {
  name: "carryOn",
  components: {
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    [Stepper.name]:Stepper
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      qrcodeObj:null,
      detailObj:{},
      statusDict:{}
    };
  },
  mounted() {
    this.getQrcode()
    // this.getDetail();
    getDictionary('ticket_status').then(res=>{
      if(res.success){
        res.data.map(item=>{
          this.$set(this.statusDict,item.dictKey,item.dictValue);
        });
      }
    })
  },
  methods: {
      getDetail(){
        const {id} = this.$route.query;
        getDetail(id).then(res=>{
          if(res.success){
            this.detailObj=res.data;
            if(this.detailObj.ticketStatus===0){
              this.getQrcode();
            }
          } 
        })
      },
      getQrcode(){
        const {id} = this.$route.query;
        if (this.qrcodeObj) {
            let qrcodeHtml = document.getElementById("qrcodea");
            qrcodeHtml.innerHTML = "";
        }
        this.qrcodeObj = new QRCode("qrcodea", {
            text: id,
            width: 150,
            height: 150,
            colorDark: "#000000",
            colorLight: "#ffffff",
            // correctLevel: QRCode.CorrectLevel.H,
        });
      },
  },
};
</script>

<style lang="scss" scoped>
.order_detail {
  width: 100%;
  min-height: 100vh;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .detail_map{
    width: 240px;
    padding: 24px;
    background-color: #1677FF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span{
      margin-bottom: 24px;
      color: #fff;
      font-size: 24px;
    }
  }
  .detail_top{
    .top_header{
      height: 120px;
      border-radius: 0 0 10px 10px;
      background-image: linear-gradient(to right,#0088FF,#1677FF);
      display: flex;
      justify-content: center;
      #qrcodea{
        position: absolute;
        top: 24px;
        width: 162px;
        height: 162px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .lose_efficacy_bg{
        position: absolute;
        top: 24px;
        width: 162px;
        height: 162px;
        background: url('~@/assets/images/lose_efficacy.png') no-repeat;
        background-size: cover;
      }
    }
    .top_body{
      padding: 100px 12px 16px;
      margin-bottom: 12px;
      background-color: #fff;
      .matter{
        margin-bottom: 16px;
        color: #333;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        span:nth-of-type(2){
          margin-left: 12px;
          color: #00B478;
          font-size: 15px;
          font-weight: 500;
        }
      }
      .time{
        display: flex;
        >div{
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          span:nth-of-type(1){
            margin-bottom: 12px;
            color: #8B8B8B;
            font-size: 15px;
          }
          span:nth-of-type(2){
            width: 160px;
            border-radius: 5px;
            background-color: #EFEFEF;
            color: #333;
            font-size: 15px;
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }
    .top_rows{
      padding: 16px 12px;
      background-color: #fff;
      font-size: 14px;
      .row{
        display: flex;
        justify-content: space-between;
        +.row{
          margin-top: 16px;
        }
        &:last-of-type{
          padding-top: 16px;
          border-top: 1px dotted #707070;
        }
        span:nth-of-type(1){
          color: #8B8B8B;
          margin-right: 12px;
        }
        span:nth-of-type(2){
          color: #333;
        }
      }
    }
  }
}
</style>